import { useState } from "react";
import { Box } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

import PreviewContent from "./PreviewContent";
import PreviewFooter from "./PreviewFooter";
import PreviewTypeTab from "./PreviewTypeTab";

export default function ArrivalNoticePreview({
  shipmentId,
}: {
  shipmentId: number;
}) {
  /** TODO: 로직이 복잡해지면 useHandleTab/useHandlePreview 훅으로 추상화 */
  const [tab, setTab] = useState<"AN" | "BL">("AN");
  const [previewIndex, setPreviewIndex] = useState(0);

  const handlePreviewIndexChange = (v: "+" | "-") => {
    if (v === "+") return setPreviewIndex((prev) => prev + 1);

    return setPreviewIndex((prev) => prev - 1);
  };

  const handleTabChange = (v: "AN" | "BL") => {
    setPreviewIndex(0);
    setTab(v);
  };

  const { data } = TRELLO_BID_QUERY.useGetTrelloAttachments({
    bidId: shipmentId,
  });

  const previewContentList =
    tab === "AN"
      ? data?.filter((v) => v.domain === "arrivalNotice")
      : data?.filter((v) => v.domain === "BL");

  return (
    <Box>
      <PreviewTypeTab tab={tab} onTabChange={handleTabChange} />

      <PreviewContent previewContent={previewContentList?.[previewIndex]} />

      <PreviewFooter
        onPreviewIndexChange={handlePreviewIndexChange}
        isPreviousButtonDisabled={previewIndex === 0}
        /** 더 이상 파일이 없는 경우  */
        isNextButtonDisabled={
          !previewContentList?.length ||
          previewIndex + 1 === previewContentList?.length
        }
      />
    </Box>
  );
}
