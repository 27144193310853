import { Button } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";

import useCheckCargoManifest from "./hooks/useCheckCargoManifest";
import useCheckNecessaryFile from "./hooks/useCheckNecessaryFile";

import AN_SUBMIT_AVAILABLE_STATUS_LIST from "./AN_SUBMIT_AVAILABLE_STATUS_LIST";
import PreviewAndFormModal from "./PreviewAndFormModal";

export default function ArrivalNoticeSubmit({
  shipmentId,
  freightType,
  shipmentStatus,
}: {
  shipmentId: number;
  freightType: FreightType;
  shipmentStatus: BidProjectStatus;
}) {
  const {
    hasCargoManifest,
    handleCargoManifestCheck,
    showsModal,
    handleModalClose,
  } = useCheckCargoManifest({
    shipmentId,
  });

  const { hasNecessaryFile } = useCheckNecessaryFile({ shipmentId });

  const canClickButton =
    hasNecessaryFile && // AN, BL 업로드
    freightType === "FCL" && // 화물 타입 FCL
    AN_SUBMIT_AVAILABLE_STATUS_LIST.includes(shipmentStatus); // 제출 가능 운송상태

  return (
    <>
      <Button
        variant="contained"
        color="warning"
        onClick={handleCargoManifestCheck}
        disabled={!canClickButton}
      >
        적하목록 제출
      </Button>

      {showsModal && (
        <PreviewAndFormModal
          hasCargoManifest={hasCargoManifest}
          showsModal={showsModal}
          onModalClose={handleModalClose}
          shipmentId={shipmentId}
        />
      )}
    </>
  );
}
