import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { ForwardingAdminAttachment } from "@sellernote/_shared/src/types/forwarding/trello";

import FileViewer from "../../../../../../../../../containers/FileName/FilePreview/FileViewer";
import useFileUrl from "../../../../../../../../../hooks/useFileUrl";

export default function PreviewContent({
  previewContent,
}: {
  previewContent: ForwardingAdminAttachment | undefined;
}) {
  const [showsPreview, setShowsPreview] = useState(false);
  const [s3Url, setS3Url] = useState("");

  const fileExtension = previewContent?.name.substring(
    previewContent.name.lastIndexOf(".") + 1
  );

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const showPreview = useCallback(
    ({ downloadKey }: { downloadKey: string }) => {
      if (!downloadKey) return;

      getFileDownloadUrl(
        {
          pathParams: {
            key: downloadKey,
          },
        },
        {
          onSuccess: ({ data }) => {
            setS3Url(data.url);
            setShowsPreview(true);
          },
        }
      );
    },
    [getFileDownloadUrl]
  );

  /**
   * 미리보기가 이벤트가 아닌 렌더링 상태(onMount)에 의존하기 때문에 useEffect 사용
   */
  useEffect(() => {
    if (!previewContent?.key) return;

    showPreview({ downloadKey: previewContent.key });
  }, [previewContent?.key, showPreview]);

  const { fileUrl, isPdf, isHwp, hwpRenderDivRef } = useFileUrl(
    s3Url,
    fileExtension ?? "pdf"
  );

  return (
    <Box sx={{ mt: 2 }}>
      {showsPreview && (
        <FileViewer
          isHwp={isHwp}
          isPdf={isPdf}
          fileExtension={fileExtension ?? "pdf"}
          imageWidth={100}
          fileUrl={fileUrl}
          hwpRenderDivRef={hwpRenderDivRef}
          handleDocumentLoadSuccess={() => {}}
          pdfScale={1.4}
          pageNumber={1}
        />
      )}
    </Box>
  );
}
