/**
 * BOX_CONTAINER_STYLE은 중복 스타일이 아니지만,
 * 해당 컴포넌트 로직이 복잡할 것으로 예상되어 관심사 분리 목적으로 분리함
 */
const BOX_CONTAINER_STYLE = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
};

const COMMON_BOX_STYLE = {
  position: "absolute" as const,
  top: "50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  borderRadius: 2,
  padding: 2,
  overflow: "scroll",
};

export { BOX_CONTAINER_STYLE, COMMON_BOX_STYLE };
