import { Box, Divider, TextField, Typography } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

import { ContainerFormChangeProps } from "../../../types";
import {
  CONTAINER_STYLE,
  CUSTOM_TEXT_FIELD_STYLE,
  TITLE_STYLE,
} from "../styles";

export default function ContainerInfoForm({
  form,
  onContainerFormChange,
  isEditMode,
}: {
  form: CargoManifestInfoProps;
  onContainerFormChange: ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => void;
  isEditMode: boolean;
}) {
  const containerNumber = form.containers?.length ?? 0;

  return (
    <>
      <Typography variant="h6" mt={1}>
        컨테이너 정보 (총 {containerNumber}개)
      </Typography>

      <Box
        mt={1}
        display={"flex"}
        overflow={"auto"}
        sx={{ "&::-webkit-scrollbar": { height: 3 } }}
      >
        {form.containers?.map((container, index) => {
          const hasContainerPackageCount = "containerPackageCount" in container;

          const isLastItem = containerNumber === index + 1;

          return (
            <Box
              key={container.containerNumber}
              mb={containerNumber > 3 ? 2 : 0}
              display={"flex"}
            >
              <Box display="flex" flexDirection={"column"} gap={1}>
                <div style={CONTAINER_STYLE}>
                  <span style={{ ...TITLE_STYLE, minWidth: 100 }}>
                    CNTR NO.
                  </span>

                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    height={27}
                    pl={1.5}
                  >
                    {container.containerNumber}
                  </Box>
                </div>

                <div style={CONTAINER_STYLE}>
                  <span style={{ ...TITLE_STYLE, minWidth: 100 }}>
                    Size/Type
                  </span>

                  <TextField
                    value={container.containerType}
                    variant="outlined"
                    size="small"
                    sx={CUSTOM_TEXT_FIELD_STYLE}
                    name="sealNo"
                    onChange={(e) =>
                      onContainerFormChange({
                        index,
                        key: "containerType",
                        value: e.target.value,
                      })
                    }
                    disabled={!isEditMode}
                  />
                </div>

                <div style={CONTAINER_STYLE}>
                  <span style={{ ...TITLE_STYLE, minWidth: 100 }}>
                    Seal No.
                  </span>

                  <TextField
                    value={container.sealNo}
                    variant="outlined"
                    size="small"
                    sx={CUSTOM_TEXT_FIELD_STYLE}
                    name="sealNo"
                    onChange={(e) =>
                      onContainerFormChange({
                        index,
                        key: "sealNo",
                        value: e.target.value,
                      })
                    }
                    disabled={!isEditMode}
                  />
                </div>

                <div style={CONTAINER_STYLE}>
                  <span style={{ ...TITLE_STYLE, minWidth: 100 }}>
                    포장개수
                  </span>

                  <TextField
                    value={undefined}
                    defaultValue={
                      hasContainerPackageCount
                        ? container.containerPackageCount
                        : undefined
                    }
                    variant="outlined"
                    size="small"
                    sx={CUSTOM_TEXT_FIELD_STYLE}
                    name="packageCount"
                    type="number"
                    onChange={(e) => {
                      onContainerFormChange({
                        index,
                        key: "containerPackageCount",
                        value: Number(e.target.value),
                      });
                    }}
                    disabled={!isEditMode}
                  />
                </div>
              </Box>

              {containerNumber > 1 && !isLastItem && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mr: 2, ml: 2 }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
}
