import { Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

import useSaveCargoManifest from "./hooks";

export default function EditAndSaveButton({
  isEditMode,
  setIsEditMode,
  shipmentId,
  form,
  cargoManifestInfo,
  canSubmit,
}: {
  isEditMode: boolean;
  /** 함수형 업데이트를 위해 SetStateAction 타입 사용 */
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  shipmentId: number;
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
  canSubmit: boolean;
}) {
  const { handleManifestInfoUpdate, ResponseHandlerOfUpdateCargoManifest } =
    useSaveCargoManifest({
      form,
      cargoManifestInfo,
      shipmentId,
      isEditMode,
      setIsEditMode,
    });

  return (
    <>
      <Button
        variant="contained"
        sx={{
          background: "coral",
          "&.MuiButtonBase-root:hover": { background: "coral" },
        }}
        onClick={handleManifestInfoUpdate}
        size="small"
        disabled={!canSubmit}
      >
        {isEditMode ? "저장" : "수정"}
      </Button>

      {ResponseHandlerOfUpdateCargoManifest}
    </>
  );
}
