import { Skeleton } from "@mui/material";

export default function CargoManifestSkeleton() {
  return (
    <>
      {[...Array(13)].map((_, i) => {
        return <Skeleton animation="wave" height={50} key={i} />;
      })}
    </>
  );
}
