import { Dispatch, SetStateAction } from "react";
import { useQueryClient } from "react-query";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

import useSnackbar from "../../../../../../../../../../../hooks/useSnackbar";
import { checkUpdatedProperties } from "../utils";

export default function useSaveCargoManifest({
  form,
  cargoManifestInfo,
  shipmentId,
  isEditMode,
  setIsEditMode,
}: {
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
  shipmentId: number;
  isEditMode: boolean;
  /** 함수형 업데이트를 위해 SetStateAction 타입 사용 */
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  /** 각 컨테이너 포장 개수 총합이 전체 포장개수 총합과 맞는지 확인 */
  const isValidTotalPackageCount = (() => {
    const totalPackageCount = form.packageCount;

    const containerPackageCountSum = form.containers?.reduce((acc, cur) => {
      return acc + (cur.containerPackageCount ?? 0);
    }, 0);

    return totalPackageCount === containerPackageCountSum;
  })();

  const onSuccess = {
    onSuccess: () => {
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getCargoManifest({ shipmentId })
      );

      handleSnackbarOpen("적하목록 정보를 성공적으로 변경했습니다.");
    },
  };

  const {
    mutate: updateCargoManifest,
    ResponseHandler: ResponseHandlerOfUpdateCargoManifest,
  } = TRELLO_BID_QUERY.useUpdateCargoManifest({ shipmentId });

  const handleManifestInfoUpdate = () => {
    if (isEditMode) {
      if (!isValidTotalPackageCount) {
        handleSnackbarOpen("포장개수 합을 확인해주세요.", "warning");
        return;
      }

      updateCargoManifest(
        { ...checkUpdatedProperties({ form, cargoManifestInfo }) },
        onSuccess
      );
    }

    setIsEditMode((prev) => !prev);
  };

  return {
    handleManifestInfoUpdate,
    ResponseHandlerOfUpdateCargoManifest,
  };
}
