import { Tab, Tabs } from "@mui/material";

const COMMON_STYLE = { borderBottom: 1, borderColor: "divider" };

export default function PreviewTypeTab({
  tab,
  onTabChange,
}: {
  tab: "AN" | "BL";
  onTabChange: (v: "AN" | "BL") => void;
}) {
  return (
    <Tabs value={tab} onChange={(_, v) => onTabChange(v)}>
      <Tab label="AN(적하목록)" value="AN" sx={COMMON_STYLE} />
      <Tab label="B/L" value="BL" sx={COMMON_STYLE} />
    </Tabs>
  );
}
