import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";

import useGetCargoManifestInfo from "./hooks";
import { BOX_CONTAINER_STYLE, COMMON_BOX_STYLE } from "./styles";

import ArrivalNoticePreview from "./ArrivalNoticePreview";
import CargoManifestInfo from "./CargoManifestInfo";
import CargoManifestSkeleton from "./CargoManifestSkeleton";

export default function PreviewAndFormModal({
  hasCargoManifest,
  showsModal,
  onModalClose,
  shipmentId,
}: {
  hasCargoManifest: boolean;
  showsModal: boolean;
  onModalClose: () => void;
  shipmentId: number;
}) {
  const {
    cargoManifestInfo,
    showsSkeleton,
    ResponseHandlerOfCreateNewCargoManifestInfo,
  } = useGetCargoManifestInfo({
    shipmentId,
    hasCargoManifest,
  });

  return (
    <>
      <Modal open={showsModal} onClose={onModalClose}>
        <Box sx={BOX_CONTAINER_STYLE}>
          <Box
            sx={{ ...COMMON_BOX_STYLE, left: "27%", height: 800, width: 800 }}
          >
            <ArrivalNoticePreview shipmentId={shipmentId} />
          </Box>

          <Box
            sx={{
              ...COMMON_BOX_STYLE,
              left: "73%",
              maxHeight: 800,
              width: 930,
            }}
          >
            {showsSkeleton && <CargoManifestSkeleton />}

            {cargoManifestInfo && !showsSkeleton && (
              <CargoManifestInfo
                cargoManifestInfo={cargoManifestInfo}
                shipmentId={shipmentId}
              />
            )}
          </Box>
        </Box>
      </Modal>

      {ResponseHandlerOfCreateNewCargoManifestInfo}
    </>
  );
}
