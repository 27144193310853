import { Box, TextField } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

import {
  CONTAINER_STYLE,
  CUSTOM_TEXT_FIELD_STYLE,
  TITLE_STYLE,
} from "../styles";

export default function FirstCategoryForm({
  form,
  onFormChange,
  isEditMode,
}: {
  form: CargoManifestInfoProps;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditMode: boolean;
}) {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>MBL</span>

        <TextField
          value={form.mBL}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="mBL"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>MSN</span>

        <TextField
          value={form.MSN}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="MSN"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>
      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>HBL</span>

        <TextField
          value={form.hBL}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="hBL"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>
      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>선사부호</span>

        <TextField
          value={form.carrierCode}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="carrierCode"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>
      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>포장개수</span>

        <TextField
          value={form.packageCount}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="packageCount"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>
      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>포장부호</span>

        <TextField
          value={form.packageSign}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="packageSign"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>총 중량(KG)</span>

        <TextField
          value={form.totalWeight}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="totalWeight"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>총 용적(CBM)</span>

        <TextField
          value={form.totalCBM}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="totalCBM"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>
      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>품명</span>

        <TextField
          value={form.itemName}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="itemName"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>특수화물 코드</span>

        <TextField
          value={form.spclCode}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="spclCode"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>배정구분 코드</span>

        <TextField
          value={form.assignCode}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="assignCode"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>

      <div style={CONTAINER_STYLE}>
        <span style={TITLE_STYLE}>배정장소</span>

        <TextField
          value={form.terminalCode}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="terminalCode"
          onChange={onFormChange}
          disabled={!isEditMode}
        />
      </div>
    </Box>
  );
}
