import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

import useSnackbar from "../../../../../../../../../hooks/useSnackbar";
import { ContainerFormChangeProps } from "../types";

import EditAndSaveButton from "./EditAndSaveButton";
import FormList from "./FormList";
import RequestButton from "./RequestButton";

export default function CargoManifestInfoForm({
  form,
  cargoManifestInfo,
  onFormChange,
  onContainerFormChange,
  onETAChange,
  shipmentId,
}: {
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContainerFormChange: ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => void;
  onETAChange: (value: string | null) => void;
  shipmentId: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  /** 폼이 수정 가능한 상태인지. true일 경우 TextField의 disabled 속성이 false로 변함 */
  const [isEditMode, setIsEditMode] = useState(false);

  /** 적하목록 수정, 제출 가능한 상태 */
  const canSubmit = form.status === null || form.status === "fail";

  /**
   * 이미 제출됐거나, 제출 대기중인 적하목록인 경우 경고 모달을 띄움
   * 의존성 배열은 경고 모달이 꺼지지 않는 문제가 있어 비워둠
   */
  useEffect(() => {
    if (!canSubmit) {
      handleSnackbarOpen(
        "이미 제출됐거나, 제출 대기중인 적하목록입니다.",
        "warning"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">적하목록 신고 정보</Typography>

          <div>
            <EditAndSaveButton
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              shipmentId={shipmentId}
              form={form}
              cargoManifestInfo={cargoManifestInfo}
              canSubmit={canSubmit}
            />

            <RequestButton
              shipmentId={shipmentId}
              isEditMode={isEditMode}
              canSubmit={canSubmit}
            />
          </div>
        </Box>

        <Divider />

        <FormList
          form={form}
          onFormChange={onFormChange}
          onContainerFormChange={onContainerFormChange}
          onETAChange={onETAChange}
          isEditMode={isEditMode}
        />
      </Box>
    </>
  );
}
