import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

import useHandleManifestInfo from "./hooks";

import CargoManifestInfoForm from "./CargoManifestInfoForm";

export default function CargoManifestInfo({
  cargoManifestInfo,
  shipmentId,
}: {
  cargoManifestInfo: CargoManifestInfoProps;
  shipmentId: number;
}) {
  const { form, handleContainerFormChange, handleFormChange, handleETAChange } =
    useHandleManifestInfo({ cargoManifestInfo });

  return (
    <CargoManifestInfoForm
      form={form}
      cargoManifestInfo={cargoManifestInfo}
      onFormChange={handleFormChange}
      onContainerFormChange={handleContainerFormChange}
      onETAChange={handleETAChange}
      shipmentId={shipmentId}
    />
  );
}
